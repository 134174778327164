import api from './api'
import apiConfiguration from './apiConfiguration';
import usersManagement from './implementations/usersManagement';
import railDataImpl from './implementations/railData';
import railGateDataImpl from './implementations/railGateData';
import trainDataImpl from './implementations/trainData';
import yardDataImpl from './implementations/yardData';
import vesselDataImpl from './implementations/vesselData';
import reportDataImpl from './implementations/reportData';
import registryDataImpl from './implementations/registryData';

export default {
    install: (Vue) => {
        Vue.apiConfiguration = apiConfiguration;
        Vue.api = api;
        Vue.api.railData = railDataImpl;
        Vue.api.railGateData = railGateDataImpl;
        Vue.api.trainData = trainDataImpl;
        Vue.api.yardData = yardDataImpl;
        Vue.api.vesselData = vesselDataImpl;
        Vue.api.reportData = reportDataImpl;
        Vue.api.registryData = registryDataImpl;
        Vue.prototype.$apiConfiguration = apiConfiguration;
        Vue.prototype.$api = api;
        Vue.prototype.$api.railData = railDataImpl;
        Vue.prototype.$api.railGateData = railGateDataImpl;
        Vue.prototype.$api.trainData = trainDataImpl;
        Vue.prototype.$api.usersManagement = usersManagement;
    }
}