import 'vuetify/dist/vuetify.css';
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue';
import Vuetify from 'vuetify';
import DatetimePicker from 'vuetify-datetime-picker';
import colors from 'vuetify/lib/util/colors';

const vuetifyInstance = new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            light: {
                // primary: '#213e79',
                // secondary: '#c0d8ed', 
                primary: colors.blue.darken2,
                secondary: colors.blue.darken4,
                accent: '#4ea3ed',
                error: colors.red.darken3,
                info: '#2196f3',
                success: colors.teal.darken2,
                warning: colors.yellow.darken3,
                header: colors.blue.lighten5,               
                panelheader: colors.blueGrey.lighten5,
                vesselMenu: colors.cyan.darken1,
                railMenu: colors.amber.darken1,
                yardMenu: colors.blue.darken4,
                gateMenu: colors.green.darken1,
                reportsMenu: colors.deepOrange.accent3,
                settingsMenu: colors.grey.darken1,
                mobileMenu: colors.red.lighten1,
                disabled: colors.grey.lighten1,

                orangeBgFont: colors.orange.lighten3,
                redBgFont: colors.red.lighten3,
                greyBgFont: colors.grey.lighten2,
                greenBgFont: colors.green.lighten3,

                redFont: colors.red.darken2,
                greyFont: colors.grey.darken2,
                greenFont: colors.green.darken2,
                blueFont: colors.blue.darken2,

                
                // vesselMenu: colors.cyan.darken1,
                // railMenu: colors.red.darken1,
                // yardMenu: colors.blue.darken1,
                // gateMenu: colors.yellow.darken1,
                // reportsMenu: colors.purple.darken1,
                // settingsMenu: colors.grey.darken1,
            }
        }
    }
});

Vue.use(Vuetify);
Vue.use(DatetimePicker);

export default vuetifyInstance;