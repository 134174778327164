import api from "../api";
import apiConfiguration from "../apiConfiguration";
import _ from "lodash";

export default {
  async getCountryFlags() {
    return await api.get(apiConfiguration.BASE_PATH_VESSEL + "vessels/country-flags");
  },
  async getCallsByDateRange(direction, dateFrom, dateTo, simulation) {
    return await api.get(apiConfiguration.BASE_PATH_VESSEL + "calls/by-date-range", {
      params: { direction: direction, dateFrom: dateFrom, dateTo: dateTo, simulation: simulation },
    });
  },
  async getSimdCall(status, callId) {
    return await api.get(apiConfiguration.BASE_PATH_VESSEL + "calls/sim-call/" + status + "/" + callId);
  },
  async getCallById(callId) {
    return await api.get(apiConfiguration.BASE_PATH_VESSEL + "calls/" + callId);
  },
  async getCallListsById(callId) {
    return await api.get(apiConfiguration.BASE_PATH_VESSEL + "calls/call-lists/" + callId);
  },
  async saveCall(call) {
    if (call.id) {
      return await api.put(apiConfiguration.BASE_PATH_VESSEL + "calls/" + call.id, call);
    } else {
      return await api.post(apiConfiguration.BASE_PATH_VESSEL + "calls", call);
    }
  },
  async getVessels(containsStr) {
    return await api.get(apiConfiguration.BASE_PATH_VESSEL + "vessels/search-vessels/" + containsStr);
  },
  async getStatuses() {
    return await api.get(apiConfiguration.BASE_PATH_VESSEL + "calls/status");
  },
  async searchCalls(filters) {
    return await api.post(apiConfiguration.BASE_PATH_VESSEL + "calls/search", filters);
  },
};
