/**
 * Ruoli (attributo role) definiti in classe it.circle.railTos.enums.RuoloUtenteEnum, in RailTosRest
 *  Possono essere definiti più ruoli, es: role: "SETTINGS,USER" (visiibile solo ad utenti con ENTRAMBI i ruoli)
 */


import i18n from "../i18n";

export default [
  {
    label: "Vessel",
    color: "vesselMenu",
    menuTreeVisible: true,
    menuTree: [
      {
        label: i18n.t("Calls"),
        icon: "mdi-ferry",
        role: "VESSEL",
        to: "/vessel/callsDaily",
      }
    ]
  },
  {
    label: "Rail",
    color: "railMenu",
    menuTreeVisible: true,
    menuTree: [
      {
        label: "Dashboard",
        icon: "mdi-view-dashboard",
        role: "USER|EXT_TOS_USER",
        to: "/",
      },
      {
        label: i18n.t("menu_rail_design"),
        icon: "mdi-lightbulb-on-10",
        role: "USER|EXT_TOS_USER",
        items: [{ label: i18n.t("menu_tracce"), to: "/anagrafiche/tracce" }],
      },
      {
        label: i18n.t("menu_rail_gate"),
        icon: "mdi-boom-gate-up",
        role: "USER|RAIL_GATE|EXT_TOS_USER",
        to: "/rails/gate",
      },
      {
        label: i18n.t("menu_rail_management"),
        icon: "mdi-train",
        role: "USER|EXT_TOS_USER",
        items: [
          { label: i18n.t("menu_arrivi"), to: "/callTreno/viaggiTrenoArrivi/pianificazione" },
          { label: i18n.t("menu_partenze"), to: "/callTreno/viaggiTrenoPartenze/pianificazione" },
          { label: i18n.t("menu_pianificazione_binari"), to: "/rails/railsWeekCalendar" },
          { label: i18n.t("menu_lavorazioni"), to: "/lavorazioni" },
        ],
      },
      {
        label: i18n.t("menu_rail_execution"),
        icon: "mdi-train-car-container",
        role: "USER|EXT_TOS_USER",
        items: [
          {
            label: i18n.t("menu_arrivi"),
            excludeRole: "EXT_TOS_USER",
            to: "/callTreno/viaggiTrenoArrivi/lavorazione",
          },
          {
            label: i18n.t("menu_partenze"),
            excludeRole: "EXT_TOS_USER",
            to: "/callTreno/viaggiTrenoPartenze/lavorazione",
          },
          { label: i18n.t("menu_convogli"), to: "/rails/convogli/lavorazione" },
          { label: i18n.t("menu_vista_rt"), to: "/rails/RealTimeVisualization" },
          { label: i18n.t("menu_manovre"), to: "/rails/Manovre", role: "USER,DEV" },
        ],
      },
      {
        label: i18n.t("menu_lavorazioni"),
        icon: "mdi-gantry-crane",
        role: "GRUISTA",
        to: "/lavorazioni",
      },
      {
        label: i18n.t("menu_rail_yard"),
        icon: "mdi-warehouse",
        role: "USER|EXT_TOS_USER",
        items: [
          { label: i18n.t("menu_visite_uti"), to: "/anagrafiche/visiteUti" },
          { label: i18n.t("menu_visite_carri"), to: "/anagrafiche/visiteCarri" },
          { label: i18n.t("menu_occupazione_binari"), to: "/rails/OccupazioneBinari" },
        ],
      },
    ],
  },
  {
    label: "Yard",
    color: "yardMenu",
    menuTreeVisible: false,
    menuTree: [
      {
        label: i18n.t("YARD-inventory"),
        role: "YARD",
        icon: "mdi-layers-search",
        to: "/yard/inventory/inventoryVisits",
      },
      {
        label: i18n.t("Tutte le visite"),
        role: "YARD",
        icon: "mdi-format-list-bulleted-square",
        to: "/yard/inventory/allVisits",
      },
      {
        label: i18n.t("Tutte le visite") + " (dev)",
        role: "YARD,DEV",
        icon: "mdi-format-list-bulleted-square",
        to: "/yard/inventory/allVisitsInfinite",
      },
      {
        label: i18n.t("Truck Operations"),
        role: "YARD",
        icon: "mdi-clipboard-flow-outline",
        to: "/yard/inventory/operations",
      },
      {
        label: i18n.t("Stuffing"),
        role: "YARD",
        icon: "mdi-login-variant",
        to: "/yard/inventory/stuffing",
      },
      {
        label: i18n.t("Stripping"),
        role: "YARD",
        icon: "mdi-logout-variant",
        to: "/yard/inventory/stripping",
      },
    ],
  },
  {
    label: "Gate",
    color: "gateMenu",
    menuTreeVisible: false,
    menuTree: [
      {
        label: i18n.t("YARD-preadvice"),
        role: "GATE",
        icon: "mdi-clock-fast",
        to: "/yard/inventory/preadvicedInventoryVisits",
      },
      {
        label: i18n.t("Truck visits"),
        role: "GATE",
        icon: "mdi-truck-outline",
        to: "/yard/inventory/truckInventoryVisits",
      },
      {
        label: i18n.t("Gate-out"),
        role: "GATE",
        icon: "mdi-logout-variant",
        // mdi-cube-send
        to: "/yard/inventory/gateOutInventoryVisits",
      },
      // {
      //     label: i18n.t("Transits"),
      //     role: "GATE",
      //     icon: "mdi-boom-gate-up-outline",
      //     to: "/yard/inventory/transits",
      // },
      {
        label: i18n.t("Visit Positions"),
        role: "YA", /// ?????
        icon: "mdi-truck",
        to: "/yard/inventory/visit_positions",
      },
    ],
  },
  {
    label: "Reports",
    color: "reportsMenu",
    menuTreeVisible: false,
    menuTree: [
      {
        label: i18n.t("Handling"),
        role: "REPORTS,YARD",
        icon: "mdi-exit-to-app",
        to: "/report/handlingHeader",
      },
      {
        label: i18n.t("Soste"),
        role: "REPORTS,YARD",
        icon: "mdi-clock-fast",
        to: "/report/layoverHeader",
      },
      {
        label: i18n.t("Treni"),
        role: "REPORTS,YARD",
        icon: "mdi-train",
        to: "/report/trainHeader",
      },
      {
        label: i18n.t("Giacenze"),
        role: "REPORTS,YARD",
        icon: "mdi-warehouse",
        to: "/report/stockHeader",
      },
      {
        label: i18n.t("Genera Report Rail"),
        role: "REPORTS,USER",
        icon: "mdi-train",
        to: "/report/manageReports",
      },
    ],
  },
  {
    label: "Settings",
    color: "settingsMenu",
    menuTreeVisible: false,
    menuTree: [
      {
        label: i18n.t("menu_sys_admin"),
        icon: "mdi-clipboard-account",
        role: "ADMINISTRATOR",
        items: [
          { label: i18n.t("menu_utenti"), to: "/anagrafiche/utenti" },
          { label: i18n.t("AUDIT"), to: "/audit" },
          { label: "Upload CSV", to: "/upload-csv", role: "DEV", visible: "DEMO_UTILS_ENABLED" },
          { label: "Visit positions", to: "/yard/inventory/visit_positions", role: "DEV", visible: "DEMO_UTILS_ENABLED" },
        ],
      },
      {
        label: i18n.t("Registry"),
        role: "SETTINGS,YARD",
        icon: "mdi-text-account",
        items: [
          { label: i18n.t("Unit Types"), to: "/registry/unittypes" },
          { label: i18n.t("Goods Types"), to: "/registry/goodstypes" },
          { label: i18n.t("Damage Types"), to: "/registry/damagetypes" },
          { label: i18n.t("Companies"), to: "/registry/companies" },
          { label: i18n.t("Locode"), to: "/registry/locode", env: "NO-REGISTRY", env_value: "false" },
          { label: i18n.t("Locode"), to: "/anagrafiche/locode", env: "NO-REGISTRY" },
          { label: i18n.t("Countries"), to: "/registry/countries", env: "NO-REGISTRY", env_value: "false" },
          { label: i18n.t("Countries"), to: "/anagrafiche/nazioni", env: "NO-REGISTRY" },
          { label: i18n.t("parameters"), to: "/registry/parameters" },
        ],
      },
      {
        label: i18n.t("Vessel"),
        role: "SETTINGS,VESSEL",
        icon: "mdi-text-account",
        items: [
          { label: i18n.t("Vessels"), to: "/vessel/vessels" },
        ],
      },
      {
        label: i18n.t("Rail"),
        icon: "mdi-text-account",
        role: "SETTINGS,USER",
        items: [
          { label: i18n.t("menu_tipicarro"), to: "/anagrafiche/tipoCarri" },
          { label: i18n.t("menu_carri"), to: "/anagrafiche/carri" },
          { label: i18n.t("menu_tipiuti"), to: "/anagrafiche/tipiUti", env: "NO-REGISTRY" },
          { label: i18n.t("menu_soggetti"), to: "/anagrafiche/soggetti", env: "NO-REGISTRY" },
          { label: i18n.t("menu_nodi"), to: "/anagrafiche/nodi" },
          { label: i18n.t("menu_parco_binari"), to: "/anagrafiche/parcoBinari" },
          { label: i18n.t("menu_uti"), to: "/anagrafiche/uti" },
          { label: "Motivazioni Rifiuto", to: "/anagrafiche/motivazioniRifiuto" },
          { label: "Danni", to: "/anagrafiche/danni", env: "NO-REGISTRY" },
          { label: "Sigilli", to: "/anagrafiche/tipiSigilli", env: "NO-REGISTRY" },
          { label: "Tipi Mezzo Mov.", to: "/anagrafiche/tipiMezzoMovimentazione" },
          { label: "Mezzi Mov.", to: "/anagrafiche/mezziMovimentazione" },
          { label: i18n.t("menu_files"), to: "/filesList" },
          { label: i18n.t("Turni"), to: "/anagrafiche/turni", visible: "SHIFTS_ENABLED" },
          { label: i18n.t("menu_destinatari_report"), to: "/anagrafiche/destinatariReport", env: "NO-REGISTRY" },
        ],
      },
      {
        label: i18n.t("Yard"),
        role: "SETTINGS,YARD",
        icon: "mdi-text-account",
        items: [
          { label: i18n.t("Units"), to: "/yard/inventory/registry/units" },
          { label: i18n.t("Unit Types"), to: "/yard/inventory/registry/unittypes", env: "NO-REGISTRY" },
          { label: i18n.t("Goods Types"), to: "/yard/inventory/registry/goodstypes", env: "NO-REGISTRY" },
          { label: i18n.t("Persons"), to: "/yard/inventory/registry/persons" },
          { label: i18n.t("Handling Types"), to: "/yard/inventory/registry/handlingtypes", role: "DEV" },
          { label: i18n.t("Damage Types"), to: "/yard/inventory/registry/damagetypes", env: "NO-REGISTRY" },
        ],
      },
      {
        label: i18n.t("Gateway"),
        role: "SETTINGS",
        icon: "mdi-block-helper",
        items: [
          { label: i18n.t("Routes IN"), to: "/gateway/routesIn" },
          { label: i18n.t("Routes OUT"), to: "/gateway/routesOut" },
        ],
      },
      {
        label: i18n.t("Blocchi"),
        role: "SETTINGS,YARD",
        icon: "mdi-block-helper",
        items: [
          { label: i18n.t("BlockRules"), to: "/block/blockRules" },
          { label: i18n.t("Blocks"), to: "/block/blocks" },
        ],
      },
    ],
  },
  {
    label: "Mobile",
    color: "mobileMenu",
    menuTreeVisible: false,
    menuTree: [
      {
        label: i18n.t("Truck Operations"),
        role: "MOBILE",
        icon: "mdi-clipboard-flow-outline",
        to: "/yard/inventory/operations",
      },
      {
        label: i18n.t("Carico Convogli"),
        role: "MOBILE",
        icon: "mdi-arrow-down-bold-box-outline",
        to: "/rails/convogli/carico",
      },
      {
        label: i18n.t("Scarico Convogli"),
        role: "MOBILE",
        icon: "mdi-arrow-up-bold-box-outline",
        to: "/rails/convogli/scarico",
      },
    ],
  },
];
